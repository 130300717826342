import React from "react";
import Flyer from '../Assets/medha-flyer.png'


const Medha = () => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      //backgroundColor: "#f8f8f8", // Optional background color
      margin: 0,
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <img src={Flyer} alt="medha" style={styles.image} />
    </div>
  );
};

export default Medha;