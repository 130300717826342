import React, { useEffect } from 'react'
import '../Styles/Career.css'

const CareerList = [
  // {
  //   name: 'Front End Developer',
  //   subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  // },
  // {
  //   name: 'UI/UX Designer',
  //   subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  // },
  // {
  //   name: 'AI Architect',
  //   subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  // },
  // {
  //   name: 'Data Scientist',
  //   subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  // },
  // {
  //   name: 'Data Integration Lead',
  //   subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  // },
  // {
  //   name: 'Marketing Analyst',
  //   subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  // },
  {
    name: "S/4 HANA FICO Consultant",
    subtext: "Location: India - Remote\n The right candidate will have at least 10 years SAP FICO experience working on at least 1 S4 project, and 3 previous full implementation projects experience, doing blue print design, implementation, customization, configuration and post Go Live support of the SAP FICO and COPA, ML and COPC sub-modules. \n\nCandidates should have particular experience in implementation roll outs, data migration and strong configuration experience.\n\n • Strong knowledge on FICO business processes.\n • Strong SAP CO experience with expert Product costing implementation experience in MTO/MTS/ETO manufacturing scenarios is most preferred\n • Experience in requirements gathering, Business process mapping\n • Preparing configuration documents and Business Blue Print documents and RIECEF Objects.\n • Strong Experience in conducting SAP Process workshops / Blueprint solution workshops in large client engagements and drive decisions \n\nExperience working on SAP S4 HANA project is a must."
  },
  {
    name: "CRM TPM TECHNICAL  Consultant",
    subtext: "Location: India - Remote\n• SAP CRM TPM Professional with 4-8 yrs. experience in SAP CRM consulting with more focus on TPM implementation / Support projects.\n• Good understanding in CRM/ TPM processes, related tables, master data, and understanding of integration with ECC SD, FI, Fund management and Claims processing – Added advantage\n• Experience in integration environment with ECC, BI and other third-party systems and familiar with integration touch points.\n• Experience in Data loads between ECC and CRM (Customers, Materials and Condition records), middleware enhancements - BDOC extensions.\n• Hands on experience in CRM UI set up. AET configuration & related CRM classes\n• Knowledge and experience of CRM BOL/GENIL objects\n• Ability to code on BADI and Classes\n• Strong ABAP / ABAP on HANA expertise – Good knowledge of BAPIs, business events, user-exits in the area of SAP CBP, SAP TPM, SAP ECC\n• Knowledge of integration with BW/ IP/ BPS / planning Engine is a plus\n• Experience of supporting complex change and / or multi-disciplinary projects\n• Critical thinker and ability to demonstrate logical approach to problem solving\n• Able to prioritize work and balance competing demands"
  },
  {
    name:"SAP IBP Sr. Consultant",
    subtext:"Location: India - Remote\n Qualifications\n\nRequired:\n\n •  At least 5 years' relevant SAP consulting experience in a professional services environment, with a large or boutique consulting firm, or serving as an internal consultant within industry\n •  Deep knowledge and business process experience with SAP IBP (Integrated Business Planning) solutions\n •  Functional configuration and design experience with SAP IBP , specifically experience with at least one of these modules - SAP IBP Demand Planning, SAP IBP Response & Supply, SAP IBP Inventory Optimization, or SAP IBP Sales & Operations Planning\n •  S4 HANA implementation experience\n •  Participation in all phases of at least 2 SAP Supply Chain / IBP implementations with at least 1 in a Lead role\n •  Experience with blueprint design workshops with cross-functional stakeholders\n •  Ability to travel up to 50% based on work you do and the client and industries/sectors you serve\n •  Bachelor's degree or equivalent\n\n Desired:\n\n •Knowledge and experience with SAP PP, and Production Planning & Detailed Scheduling (PPDS)\n •Experience with i ntegration between SAP ECC or S/4HANA and IBP using SAP Cloud Integration-Data Services (CI-DS) or Real Time Integration (RTI)\n •Understanding of SAP ASAP and Agile methodologies\n •Signavio experience \n •Greenfield implementation experience\n •Master's degree"
  }
]

function Career() {

  useEffect(() => {
    window.scrollTo(0,0);

  },[])

    document.querySelectorAll('.nav-ext').forEach(x => {
        x.style.display = 'none'
    })

    function showCareerDescr(e){
      let targ = e.currentTarget
      let careerDescr = targ.parentNode.id
      targ.classList.toggle('show')
      if(targ.className === 'car-descr show'){
        targ.innerHTML = `<svg style={{pointerEvents:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>`
        document.getElementById('showCareer'+careerDescr).style.display='flex'
      }else{
        targ.innerHTML = `<svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>`
        document.getElementById('showCareer'+careerDescr).style.display='none'
      }
    }
    
  return (
    <div className='career'>
    <div className="career-content">
      <h1 className="career-title">Career</h1>
      <div className="career-descr">
        {CareerList.map((i,k) => {
          return(
          <div key={k} className="career-item-container">
            <div id={i.name} className='career-item'>
              {i.subtext && 
                <div onClick={showCareerDescr} className='car-descr'>
                  <svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
                </div>
              }
              <p>{i.name}</p>
            </div>
            {i.subtext && 
            <p id={`showCareer${i.name}`} className="career-subtext">{i.subtext.split('\n').map((line, y) => {
              return <span key={y} >{line}<br/></span>
            })
            }
            <br/><div className="form-buttons">
            <a href="mailto:service@siddhiscientific.com" className="primary button-link">
              Apply
            </a>
            </div>
            </p>
            }
          </div>
          )
        })}

      </div>
    </div>
  </div>
  )
}

export default Career
